#ic-valuation-widget {
    max-width: unset !important;
}

#ic-valuation-widget .ic-container .ic-loader {
    @apply py-4;
}

#ic-valuation-widget .ic-container .loader {
    border-bottom-color: #94cc00 !important;
}

#ic-valuation-widget .ic-container .box {
    border: 0 !important;
    box-shadow: none !important;
}

#ic-valuation-widget .ic-container .box input,
#ic-valuation-widget .ic-container .box select {
    border-color: #707070 !important;
}

#ic-valuation-widget .ic-container .box label{
    text-transform: uppercase !important;
    font-weight: bold !important;
}

#ic-valuation-widget .ic-container .box label[for='ic-has-client-reference'],
#ic-valuation-widget .ic-container .box label[for='question-certify']
{
    text-transform: none !important;
    font-weight: normal !important;
}

#ic-valuation-widget .ic-container .box input[type='checkbox']:checked,
#ic-valuation-widget .ic-container .box .question-form .question input[type="checkbox"]:checked {
    background-color: #94cc00 !important;
}

#ic-valuation-widget .ic-container .box .buttons .button {
    background-color: #252F38 !important;
    border-color: #252F38 !important;
    border-radius: 1.5rem !important;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

#ic-valuation-widget .ic-container .box .buttons .button.cash-button {
    background-color: unset !important;
}
#ic-valuation-widget .ic-container .box .buttons .button.cash-button:hover {
    background-color: #252F38 !important;
}

#ic-valuation-widget .ic-container .box .vehicle-loaded dl.vehicle-data-grid .row dt.vehicle-data-title {
    color: unset !important;
}

#ic-valuation-widget .ic-container .box h1,
#ic-valuation-widget .ic-container .box h2,
#ic-valuation-widget .ic-container .box h3 {
    @apply text-xl;
    font-weight: bold;
}

#ic-valuation-widget .ic-container .box .question-form,
#ic-valuation-widget .ic-container .box .vehicle-data-grid {
    margin-top: 1rem !important;
}

#ic-valuation-widget .ic-container .box .vehicle-data-grid {
    margin-bottom: 1rem !important;
}


#ic-valuation-widget .ic-container .box .success-message {
    background-color: #94cc00 !important;
}
#ic-valuation-widget .ic-container .box .success-message .wrapper .icon svg,
#ic-valuation-widget .ic-container .box .success-message .wrapper p.heading,
#ic-valuation-widget .ic-container .box .success-message .wrapper p.error {
    color: #FFFFFF !important;
}

#ic-valuation-widget .ic-container .box .accept-form-depot .depot-item {
    border-color: #707070 !important;
}
#ic-valuation-widget .ic-container .box .accept-form-depot .depot-item.selected {
    border-color: #94cc00 !important;
}
