@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "./components/swiper.css";
@import "./components/wp.css";
@import "./components/widgetOverrides.css";

[v-cloak] {display: none}

.size-full{
  @apply w-full;
}
html{
  @apply h-full;
}
html, body{
  @apply  min-h-full;
}
body {
  @apply flex w-full min-h-full;
}

.button {
  @apply text-gray-700 border-gray-300 hover:text-green-400 hover:border-green-400 uppercase inline-flex items-center whitespace-nowrap justify-center px-5 py-2 border-2 leading-6 font-semibold rounded-full bg-transparent transition duration-150 ease-in-out cursor-pointer;
}
.button:focus{
  @apply outline-none shadow-outline;
}
.button:disabled{
  @apply opacity-50 cursor-not-allowed;
}
.button.green {
  @apply bg-green-500 border-green-500 text-gray-900 hover:bg-green-600 hover:border-green-600;
}

.primary-menu .menu-item a {
  @apply text-base uppercase font-medium transition hover:opacity-75;
}

.primary-menu .current-menu-item a{
  @apply opacity-75;
}


.button.solid.black {
  @apply text-white border-gray-700 hover:border-green-400 bg-gray-700 hover:bg-green-400;
}

[type='text'],
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'],
[multiple],
textarea,
select {
  @apply px-0 py-2 border-0 border-b-2 bg-transparent border-gray-200 w-full focus:border-gray-700 focus:ring-0 focus:ring-gray-200 transition-colors;
}

.textarea.white,
.input.white{
  @apply placeholder-white placeholder-opacity-50 border-white;
}

[type='checkbox']{
  @apply rounded;
}
[type='checkbox'],
[type='radio'] {
  @apply border-2 border-gray-900 text-green-400 focus:border-gray-700 focus:ring-2 focus:ring-gray-200 mr-4 -mt-1 transition-colors;
}

label.error{
  @apply block mt-1 text-sm text-red-500;
}

.label {
  @apply block mb-1 uppercase text-sm font-bold;
}

.accordion {
  transition: height .25s ease, opacity .25s ease;
  height: 0;
  opacity: 0;
  overflow: hidden;
}
.accordion.expanded{
  opacity: 1;
}
.label.required::after {
  content: '*';
  color: red;
  margin-left: 4px;
}

.prose .button {
  @apply no-underline;
}
